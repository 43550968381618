/*
 * Silicon | Multipurpose Bootstrap 5 Template & UI Kit
 * Copyright 2022 Createx Studio
 * Theme styles
*/

// User variables
@import "user-variables";

// Configuration & utilities
@import "variables";
@import "utilities";

// Bootstrap
@import "../vendor/bootstrap/scss/functions";
@import "../vendor/bootstrap/scss/variables";
@import "../vendor/bootstrap/scss/maps";
@import "../vendor/bootstrap/scss/mixins";
@import "../vendor/bootstrap/scss/utilities";
@import "../vendor/bootstrap/scss/root";
@import "../vendor/bootstrap/scss/reboot";
@import "../vendor/bootstrap/scss/type";
@import "../vendor/bootstrap/scss/images";
@import "../vendor/bootstrap/scss/containers";
@import "../vendor/bootstrap/scss/grid";
@import "../vendor/bootstrap/scss/tables";
@import "../vendor/bootstrap/scss/forms";
@import "../vendor/bootstrap/scss/buttons";
@import "../vendor/bootstrap/scss/transitions";
@import "../vendor/bootstrap/scss/dropdown";
@import "../vendor/bootstrap/scss/button-group";
@import "../vendor/bootstrap/scss/nav";
@import "../vendor/bootstrap/scss/navbar";
@import "../vendor/bootstrap/scss/card";
@import "../vendor/bootstrap/scss/accordion";
@import "../vendor/bootstrap/scss/breadcrumb";
@import "../vendor/bootstrap/scss/pagination";
@import "../vendor/bootstrap/scss/badge";
@import "../vendor/bootstrap/scss/alert";
@import "../vendor/bootstrap/scss/progress";
@import "../vendor/bootstrap/scss/close";
@import "../vendor/bootstrap/scss/toasts";
@import "../vendor/bootstrap/scss/modal";
@import "../vendor/bootstrap/scss/tooltip";
@import "../vendor/bootstrap/scss/popover";
@import "../vendor/bootstrap/scss/spinners";
@import "../vendor/bootstrap/scss/offcanvas";
@import "../vendor/bootstrap/scss/placeholders";
@import "../vendor/bootstrap/scss/helpers";
@import "../vendor/bootstrap/scss/utilities/api";

// Layout & componentss
@import "reboot";
@import "components";

// Dark mode
@import "dark-mode";

// User custom styles
@import "user";

.layerbg {
  background: url("../img/home/layer/user-bg.png") no-repeat;
  border-radius: 2rem !important;
}

.card {
  border-radius: 2rem !important;
}

.footerbg {
  border-top: none;
  // background: url("../img/home/layer/footer.svg") no-repeat;
}

.btn-share-button {
  width:3.5rem;
  height: 3.5rem;
}

.swiper-pointer-events {
  pointer-events: none;
}

